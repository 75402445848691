import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DynamicDialogConfig } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CtlsVocEditScreenService } from '../Modules/ctls/ctls-shared/ctls-services/ctls-voc-edit-screen.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-image-preview-voc',
  templateUrl: './image-preview-voc.component.html',
  styleUrls: ['./image-preview-voc.component.scss'],
})
export class ImagePreviewVocComponent implements OnInit {
  private imagepath = environment.imagepath;
  url: any;
  constructor(
    public config: DynamicDialogConfig,
    public sanitizer: DomSanitizer,
    public ctlsVocEditScreenService: CtlsVocEditScreenService
  ) {}
  destroy$ = new Subject();

  attachmentArray: any;
  fileURL: string;
  ngOnInit() {
    this.fileURL = this.imagepath + 'paper.svg';
    this.attachmentArray = this.config.data;
  }

  download(img) {
    this.ctlsVocEditScreenService
      .GetFileUrl(img.VOCTaskFilesListID)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res.Response) {
          const downloadLink = document.createElement('a');
          downloadLink.href = res.Response;
          downloadLink.download = img.FileName;
          downloadLink.click();
        }
      });
  }
}
