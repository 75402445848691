import { Component, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CtlsVocListingService } from '../../ctls-services/ctls-voc-listing.service';
import { Subject } from 'rxjs';
import WaveSurfer from 'wavesurfer.js/dist/wavesurfer.esm.js';
import { CtlsVocEditScreenService } from '../../ctls-services/ctls-voc-edit-screen.service';
import { CtlsSpinnerService } from '../ctls-spinner/ctls-spinner.service';

@Component({
  selector: 'app-ctls-voc-attachment',
  templateUrl: './ctls-voc-attachment.component.html',
  styleUrls: ['./ctls-voc-attachment.component.scss']
})
export class CtlsVocAttachmentComponent implements OnInit {
  @Input() idToProcess: any;
  @Input() fromPage: any;
  private imagepath = environment.imagepath;
  loader = false;
  attachments: any;
  audioAttachmentArray: any[];
  attachmentArray: any[];
  TotalAttachmentArray: any[];
  destroy$ = new Subject();
  outsideFlag=false;
// 
// @Input() audioAttachmentArray: any;
@Output()
eventFromAttachmentScreen: EventEmitter<any> = new EventEmitter<any>();
  constructor(private ctlsVocListingService: CtlsVocListingService,
    public ctlsTaskEditScreenService: CtlsVocEditScreenService,
    private spinnerService: CtlsSpinnerService,
  ) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.spinnerService.show();
    this.loader = true;
    if(this.idToProcess) {
      this.ctlsVocListingService.getVOCAttachments(this.idToProcess).pipe(takeUntil(this.destroy$)).subscribe(
        (res) => {
          this.attachments = res.Response;
          if(this.attachments && this.attachments.length > 0) {
            this.SeperateFileType();
             this.spinnerService.hide();
          }
        }
      )
    }
    this.spinnerService.hide();
  }

  
  SeperateFileType(){
    this.audioAttachmentArray = [];
    this.attachmentArray = [];
    this.TotalAttachmentArray = [];
    this.attachments.map(fileEn => {
      let fileName = fileEn.FileName.split('.');
      let splicelength = fileEn.FileName.split('.').length;
      fileEn.extension = splicelength > 0 ? fileEn.FileName.split('.')[splicelength - 1].toLowerCase(): ''
      let audioType = fileName && fileName.length ? fileName[fileName.length - 1] : '';
        if (audioType.toLowerCase() === 'm4a') {
          audioType = 'x-m4a';
        } else if (!['ogg', 'wav', 'mp3', 'webm', 'aac'].includes(audioType.toLowerCase())) {
          audioType = '';
        }
        fileEn.audioType = audioType ? 'audio/' + audioType : '';
        this.TotalAttachmentArray.push(fileEn)
        if (fileEn.audioType) {
          this.audioAttachmentArray.push(fileEn);
        } else {
          this.attachmentArray.push(fileEn);
        }
    })
    let _this = this;
    setTimeout(tym => {
      // for (var i = 0; i < _this.attachments.length; i++) {
        if (_this.attachmentArray && _this.attachmentArray.length) {
          for (let xind = 0; xind < _this.attachmentArray.length; xind++) {
            if(!this.isHolderExists("initial", xind)){
              console.log("creating "+(1+xind)+"th fileHolder for comment ")
              this.createFileHolder("fileHolder", xind, "initial-attachment-list-", _this.attachmentArray[xind]);
            }
          }
        }

        if (_this.audioAttachmentArray && _this.audioAttachmentArray.length) {
          for (let yind = 0; yind < _this.audioAttachmentArray.length; yind++) {
         
            if(!this.isAudioHolderExists("initial", yind)){
              console.log("creating "+(1+yind)+"th player for comment ")
              this.createPlayer("audioPlayer", yind, "initial-attachment-audio-list-", _this.audioAttachmentArray[yind]);
            }
          }
          
        }
        
        this.loader = false;
      // }
    }, 2000);
    
  }

  createPlayer(palyerPrefix, audioIndex, playerContainerId, audioData) {
    console.log(playerContainerId)
    let _this = this;
    const playerContainer = document.querySelector('#' + playerContainerId) as HTMLElement;
    const playerId = playerContainerId + palyerPrefix + (audioIndex + 1);
    const recording = document.createElement('div')
    recording.id = playerId;
    recording.style.display = "flex";
    recording.style.height = "30px";
    recording.style.justifyContent = "space-evenly";
    recording.style.alignItems = "center";
    recording.style.border = "1px solid #AFC6D4"
    recording.style.background = "#EEF8FF"
    recording.style.borderRadius = "5px"
    recording.style.marginBottom = "5px"
    const container = document.createElement('div')

    container.id = playerId + "Wave";
    container.onclick = (event) => {
      event.stopPropagation();
    }
    recording.appendChild(container)
 
    if(playerContainerId.includes('initial')){
     let playerContainerHolder = document.querySelector('#' +playerContainerId.replace("initial", "more")+"-holder");
     playerContainer.insertBefore(recording, playerContainerHolder)
    }else{
     playerContainer.appendChild(recording)
    }
    let audioUrl = "";
    let waveWidth = "100";
    if (audioData.URL) {
      audioUrl = audioData.URL;
    }

    // Create wavesurfer from the recorded audio
    const wavesurfer = WaveSurfer.create({
      container,
      waveColor: '#b1b1b1',
      progressColor: '#c6c6c6',
      height: 28,
      barWidth: 2,
      barHeight:0.5,
      minPxPerSec: 1,
      width: waveWidth,
      url: audioUrl,
    })
 
    // Play-Pause button
    const button = document.createElement('button');
    button.id = playerId + "PlayBtn";
    button.style.width = "10px";
    button.style.height = "24px";
    button.style.background = "transparent";
    button.style.border = "none";
    button.style.padding = "0";
    button.style.display = "flex";
    button.style.alignItems = "center";

    
 
 
    // Create an image element
    var imagePlay = document.createElement("img");
 
    // Set image source
    imagePlay.src = this.imagepath + 'VOC-Audio/play-button.svg'; // Add the URL of your image here
 
    // Set alt text for accessibility
    imagePlay.alt = "Play";
 
    // Create an image element
    var imagePause = document.createElement("img");
 
    // Set image source
    imagePause.src = this.imagepath + 'VOC-Audio/pause-button.svg'; // Add the URL of your image here
 
    // Set alt text for accessibility
    imagePause.alt = "Pause";    
 
    // Append the image to the button
    button.appendChild(imagePlay);
 
    button.onclick = (event) => {
      event.stopPropagation();
      wavesurfer.playPause();
    }
 
    recording.insertBefore(button, container)
 
    wavesurfer.on('pause', () => {
      button.removeChild(imagePause);
      button.appendChild(imagePlay);
    })
 
    wavesurfer.on('play', () => {
      button.removeChild(imagePlay);
      button.appendChild(imagePause);
    })
 
    const duration = document.createElement('div')
    duration.id = playerId + "Duration";
    duration.onclick = (event) => {
      event.stopPropagation();
    }
    recording.appendChild(duration)
 
    wavesurfer.on('ready', () => {
      duration.innerText = this.formatTime(wavesurfer.getDuration()*1000);
    })
 
    // Download button
    const buttonDownload = document.createElement('button');
    buttonDownload.id = playerId + "DownloadBtn";
    buttonDownload.style.width = "24px";
    buttonDownload.style.height = "24px";
    buttonDownload.style.background = "transparent";
    buttonDownload.style.border = "none";
    buttonDownload.style.display = "flex";
    buttonDownload.style.alignItems = "center";
    buttonDownload.style.borderRadius = "50%";
    buttonDownload.style.justifyContent = "center";
    buttonDownload.style.padding = "4px";
 
 
    // Create an image element
    var imageDelete = document.createElement("img");
 
    // Set image source
    imageDelete.src = this.imagepath + 'VOC-Audio/audio-download.svg'; // Add the URL of your image here
 
    // Set alt text for accessibility
    imageDelete.alt = "Delete";
 
    imageDelete.style.height = "100%";
 
    // Append the image to the button
    buttonDownload.appendChild(imageDelete);
 
    buttonDownload.onclick = (event) => {
      console.log("palyerPrefix=>", palyerPrefix)
      event.stopPropagation();
      this.downloadImg(audioData);
 
    }
    
 
    recording.insertAdjacentElement("beforeend", buttonDownload)
 
    const audioLabel = document.createElement('div');
    audioLabel.style.color = "#2089ad";
    audioLabel.style.fontSize = "smaller";
    audioLabel.style.width = "auto";
    audioLabel.style.right = "15px";
    audioLabel.style.position = "absolute";
    audioLabel.id = playerId + "Label";
    audioLabel.innerText = audioData.FileName;
    recording.insertAdjacentElement("beforeend",audioLabel)
 
   // Filename button
   const buttonFilename = document.createElement('button');
   buttonFilename.id = playerId + "FilenameBtn";
   buttonFilename.style.width = "18px";
   buttonFilename.style.height = "37px";
   buttonFilename.style.background = "#fff";
   buttonFilename.style.border = "none";
   buttonFilename.style.display = "flex";
   buttonFilename.style.alignItems = "center";        ;
   buttonFilename.style.position = "relative";
   buttonFilename.style.right = "-3px";

    // Create an image element
    var imageFilename = document.createElement("img");
 
    // Set image source
    imageFilename.src = this.imagepath + 'voc-listing/filename-link.svg'; // Add the URL of your image here
 
    // Set alt text for accessibility
    imageFilename.alt = "";
 
    imageFilename.style.height = "13px";
    imageFilename.style.position = "absolute";
    imageFilename.style.left = "0";
    imageFilename.style.right = "2px";
    imageFilename.style.margin = "0 auto";

 
    // Append the image to the button
    buttonFilename.appendChild(imageFilename);

   buttonFilename.onmouseover = (event) => {
     event.stopPropagation();
    this.showFilename(audioIndex, playerId)

   }

   buttonFilename.onmouseleave = (event) => {
     console.log("palyerPrefix=>", palyerPrefix)
     event.stopPropagation();
    this.hideFilename(audioIndex, playerId)

   }

   recording.insertAdjacentElement("beforeend",buttonFilename)

  }

  downloadImg(img) {
    this.ctlsTaskEditScreenService
      .GetFileUrl(img.VOCTaskFilesListID)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res.Response) {
          const downloadLink = document.createElement('a');
          downloadLink.href = res.Response;
          downloadLink.download = img.FileName;
          downloadLink.click();
        }
      });
  }

  formatTime = (milliseconds) => {
    const seconds = Math.ceil(milliseconds / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);

    // const formattedTime =
    //   this.pad(hours % 24) + ':' +
    //   this.pad(minutes % 60) + ':' +
    //   this.pad(seconds % 60);

    const formattedTime =
      minutes % 60 + ':' +
      this.pad(seconds % 60);

    return formattedTime;
  }
  pad = (number) => {
    return ('0' + number).slice(-2);
  }

  
  hideFilename = (audioIndex, playerId)=>{
    let filenameLabel = document.getElementById(playerId + "Label");
    filenameLabel.style.display = "none";
   };

  showFilename = (audioIndex, playerId)=>{
    let filenameLabel = document.getElementById(playerId + "Label");
    filenameLabel.style.display = "block";
   };;

  isHolderExists = (type, xind) => {
    let initialElement = document.getElementById(type+"-attachment-list-");
    const nodeList = initialElement.children;
    console.log(type+"-attachment-list- contains nodeList=>", nodeList)
    let exists = false;
    for(let i=0; i<=nodeList.length;i++){
      if(nodeList[i]){
        console.log(nodeList[i].id, "<==", 'audioPlayer'+(1+xind))
        if(nodeList[i].id.includes('audioPlayer'+(1+xind))||nodeList[i].id.includes('fileHolder'+(1+xind))){
          exists = true;
          break;
        }
      }
    }
    console.log('exists=', exists);
    return exists;
  }

  createFileHolder(palyerPrefix, audioIndex, playerContainerId, audioData) {
    let _this = this;
    const playerContainer = document.querySelector('#' + playerContainerId) as HTMLElement;
    const playerId = playerContainerId + palyerPrefix + (audioIndex + 1);
    const fileHolder = document.createElement('div')
    fileHolder.id = playerId + "Link";  
    fileHolder.classList.add('attachment-file-holder');
    fileHolder.classList.add(audioData.extension);
    fileHolder.title = audioData.FileName;

    // const fileName = document.createElement('p')
    // fileName.id = playerId + "Name";
    // fileName.innerText = audioData.FileName;
    // fileHolder.appendChild(fileName)

    // const fileLink = document.createElement('a')
    
    fileHolder.onclick = (event) => {
      event.stopPropagation();
      let extensions = ['jpg','jpeg','png','tiff','bmp', 'xlsx', 'xls', 'doc', 'docs', 'pdf', 'ppt', 'pptx'];
      if(extensions.includes(audioData.extension))
        this.eventFromAttachmentScreen.emit({
          operation: 'SHOW_ATTACHMENT',
          attachment: audioData,
        });
      else this.downloadImg(audioData);
      // this.showAttachmentDialog(audioData); need to call emiter
      
      // this.downloadImg(audioData); // for download
    }

    // fileLink.classList.add('attachmentDownload');
    
    // Create an image element
    // var imageFileLink = document.createElement("img");
  
    // // Set image source
    // imageFileLink.src = this.imagepath + 'voc-listing/download-attachment.svg'; // Add the URL of your image here
 
    // Set alt text for accessibility
    // imageFileLink.alt = "";

    // fileLink.appendChild(imageFileLink)
    // fileHolder.appendChild(fileLink)
 
    if(playerContainerId.includes('initial')){
     let playerContainerHolder = document.querySelector('#' +playerContainerId.replace("initial", "more")+"-holder");
     playerContainer.insertBefore(fileHolder, playerContainerHolder)
    }else{
     playerContainer.appendChild(fileHolder)
    }
  }

  isAudioHolderExists = (type, yind) => {
    let initialElement = document.getElementById(type+"-attachment-audio-list-");
    const nodeList = initialElement.children;
    console.log(type+"-attachment-list- contains nodeList=>", nodeList)
    let exists = false;
    for(let i=0; i<=nodeList.length;i++){
      if(nodeList[i]){
        console.log(nodeList[i].id, "<==", 'audioPlayer'+(1+yind))
        if(nodeList[i].id.includes('audioPlayer'+(1+yind))||nodeList[i].id.includes('fileHolder'+(1+yind))){
          exists = true;
          break;
        }
      }
    }
    console.log('exists=', exists);
    return exists;
  }

  togglePopup(){
    this.eventFromAttachmentScreen.emit({
      operation: 'CLOSE_POPUP',
      attachment: [],
    }); 
  }

  
  mouseEnter(){
    this.outsideFlag = false;
    //console.log('outsideFlag', this.outsideFlag)
  }
  mouseLeave(){
    this.outsideFlag = true;
    //console.log('outsideFlag', this.outsideFlag)
  }

  @HostListener('document:click', ['$event']) clickedOutside($event){
    console.log('outsideClick', this.outsideFlag)
    // $event.preventDefault();
    if (this.outsideFlag) {
        this.eventFromAttachmentScreen.emit({
        operation: 'CLOSE_POPUP',
        attachment: [],
      }); 
    }
  }
}
