import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import moment = require('moment');
import { DialogService } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ImagePreviewVocComponent } from 'src/app/image-preview-voc/image-preview-voc.component';
import { CtlsVocEditScreenService } from 'src/app/Modules/ctls/ctls-shared/ctls-services/ctls-voc-edit-screen.service';
import * as _ from 'lodash';
import { CtlsSpinnerService } from 'src/app/Modules/ctls/ctls-shared/ctls-components/ctls-spinner/ctls-spinner.service';
import { environment } from 'src/environments/environment';
import { element } from 'protractor';
import WaveSurfer from 'wavesurfer.js/dist/wavesurfer.esm.js';

declare var falconCustomAudioPlayer;
import { AudioPreviewComponent } from 'src/app/audio-preview/audio-preview.component';
@Component({
  selector: 'app-ctls-chat-voc-history-section',
  templateUrl: './ctls-chat-history-section.component.html',
  styleUrls: ['./ctls-chat-history-section.component.scss'],
})
export class CtlsChatHistorySectionComponent implements OnInit {
  private imagepath = environment.imagepath;
  @Output()
  eventFromChatHistory: EventEmitter<any> = new EventEmitter<any>();
  @Input() chatHistory: any;
  @Input() chat: any;
  @Input() objLanguages: any;
  @Input() saveRequestBodyObj: any;
  @Input() statusEditable: any;
  @Input() skipCount: any;
  @Input() IsShowPlus: any;
  @Input() commentReqiredCss: any;
  @Input() selectedTask: any;
  destroy$ = new Subject();

  @ViewChild('comment') comment: ElementRef;

  conversationlogData: any;
  currentAvatar: any;
  tempData = [];
  historyWrap = 'edit-history-wrap';
  // files handling variable declaration
  deletedFiles = [];
  filesToUpload = [];
  base64Files = [];
  uplodedFiles = [];
  intFileTotal = 0;
  strMaxSizeError = '';
  frmData: any;
  uploadedFileSize = 0;
  LoadFile: any;
  fileSize = 0;
  uploadedFiles = [];
  IsCommentEditRequired: boolean;
  resendObj = [];
  disable: boolean = true;
  collapseData: boolean;
  openDialogDwld = false;
  showAudioDialog = {};
  showRemainingAttachmtsFlag: any = {};
  descriptionComment = null;
  moreLoading : boolean = false;
  constructor(
    public ctlsTaskEditScreenService: CtlsVocEditScreenService,
    private dialogService: DialogService,
    public ref: ChangeDetectorRef,
    private spinner: CtlsSpinnerService,
  ) { }
  /* ngAfterViewInit(): void {
    throw new Error('Method not implemented.');
  } */

  /**
   * On Changes
   */
  ngOnChanges(changes: SimpleChanges) {
    this.skipCount = this.skipCount;
    this.IsShowPlus = this.IsShowPlus;

    if (changes.objLanguages && this.objLanguages) {
      this.objLanguages.map((item) => {
        item.Language = item.LanguageDescription + '(' + item.ISOCode + ')';
      });
    }
    if (changes.chatHistory) {
      if (this.chatHistory != undefined) {
        if (this.chatHistory[0].isFirst) {
          if (this.tempData.length) {
            this.tempData.map(item => {
              item.isEdit = false;
              item.IsCommentEditable = false;
            });
          }
        }
        this.conversationlogData = this.chatHistory;
        this.setConverastionLog();
      }
    }
    if (changes.chat) {
      if (this.chat != undefined) {
        if (this.tempData.length) {
          this.tempData.map(item => {
            item.isEdit = false;
            item.IsCommentEditable = false;
          });
        }
        this.conversationlogData = this.chat;
        this.setConverastionLog();
      }
    }
  }

  ngOnInit() {
    this.historyWrap = 'edit-history-wrap ';
  }
  setConverastionLog() {
    let sortArr = [...this.conversationlogData];
    sortArr.sort((a,b) => {
      return a.VOCTaskManagementID - b.VOCTaskManagementID;
    });
   
    this.conversationlogData.map((item,index) => {
      item.isTranslate = false;
      item.isEdit = false;
      item.editIconEnabled = false;
      item.strTransComments = '';
      item.strLanguage = '';
      item.isActive = false;
      item.CanshowResponse = true;
      item.strComments = item.Comments.trim();
      item.messageColor = 'black'
      item.commentLocalTime = moment.utc(item.CommentedDate).toDate();

      item.currentAvatar = this.getInitials(item.CommentedBy);
      if (item.UserType == 'External') {
        item.currentAvatar = '';
      }
      if (item.UserType == 'Customer' && !item.CommentedBy) {
        item.currentAvatar = '';
      }
      item.typeCss = this.setStyleBorder(item);
      item.typeAvatarCss = this.setStyleAvatar(item);
      item.typeTopCss = this.setStyleTop(item);
      if (item.TaskAction == 'Delegate')  {
        item.taskActionClass = 'delegate';
        item.taskActionTooltip = 'Delegate to'
        item.headerAction = 'Delegated to'
      }
      else if (item.TaskAction == 'Transfer')  {
        item.taskActionClass = 'transfer';
        item.taskActionTooltip = 'Transfer to'
        item.headerAction = 'Transferred to'
      }
      // else if (item.TaskAction == 'Communicate' && !!item.CustomerMailID) {
      //   item.CanshowResponse = true
      //   let indexData = index;
      //   index = index + this.skipCount;
      //   for(let i=0; i<this.tempData.length; i++){
      //     if(this.tempData[i].VOCTaskManagementID === item.VOCTaskManagementID) {
      //       break;
      //     }
      //     if(this.tempData[i].TaskAction == null || this.tempData[i].TaskAction == ""){
      //       const commonNameInEmail = item.CustomerMailID.split('@')[0].toLowerCase();
      //       const commonNameInComment = this.tempData[i].CommentedBy.split(' ')[0].toLowerCase();           
      //       if(commonNameInComment.includes(commonNameInEmail) || commonNameInEmail.includes(commonNameInComment)) {
      //         item.CanshowResponse = false
      //       }
      //     }
      //   }
      //           item.taskActionClass = 'communicate';
      //   item.taskActionTooltip = 'Communicate to'
      //   item.headerAction = 'Communicated to'
      //   if(item.UserType == 'Internal' && indexData == 0) {
      //     item.CanshowResponse = true
      //   }
      // }
      else if (item.TaskAction == 'Communicate') {
        item.CanshowResponse = true
        let splitLine = null
        if(!item.assignee) {
          const splitPattern = /^Dear (.+?),/;
          const matches = item.Comments ? item.Comments.match(splitPattern):[];
          if (matches && matches.length > 1) {
            const splitLineData = matches[0];
            splitLine = splitLineData.split('Dear ')[1]
            splitLine = splitLine.split(',')[0]
            const remainingText = item.Comments.substring(matches[0].length).trim();
          } else {
            console.log('Split pattern not found.');
          }
          item.Assignee = item.Assignee ? item.Assignee : splitLine
          item.Assignee = item.Assignee ? item.Assignee : item.CustomerMailID;

        }
        let indexData = index;
        index = index + this.skipCount;
                        for(let i=0; i<this.tempData.length; i++){
          if(this.tempData[i].VOCTaskManagementID === item.VOCTaskManagementID) {
            break;
          }
          if(this.tempData[i].TaskAction == null || this.tempData[i].TaskAction == ""){
            if(this.tempData[i].UserType == 'Customer' || this.tempData[i].UserType == 'External') {
              if(this.tempData[i].CustomerMailID.includes(item.CustomerMailID)) {
                item.CanshowResponse = false
              }  
            }
            else {
              if(this.tempData[i].CommentedBy.includes(item.Assignee) || this.tempData[i].CommentedBy.includes(item.CustomerMailID)) {
                item.CanshowResponse = false
              }
            }
            
          }
        }
        item.taskActionClass = 'communicate';
        item.taskActionTooltip = 'Communicate to'
        item.headerAction = 'Communicated to'
        
        if(item.UserType == 'Internal' && indexData == 0) {
          item.CanshowResponse = true
        }
        if(item.communicationtype === 'Customer') {
          item.Assignee = item.CustomerMailID
        } 
      }
      
      if(item.communicationtype === "Internal") item.messageColor = 'blue';
      else if(item.communicationtype === "external") item.messageColor = 'purple';
      else if(item.communicationtype === "Customer") item.messageColor = 'red';
      if(item.communicationtype === "Internal") {
        let splitLine = null
        if(!item.assignee) {
          const splitPattern = /^Dear (.+?),/;
          const matches = item.Comments ? item.Comments.match(splitPattern):[];
          if (matches && matches.length > 1) {
            const splitLineData = matches[0];
            splitLine = splitLineData.split('Dear ')[1]
            splitLine = splitLine.split(',')[0]
            const remainingText = item.Comments.substring(matches[0].length).trim();
          } else {
            console.log('Split pattern not found.');
          }
          item.Assignee = item.Assignee ? item.Assignee : splitLine
          item.Assignee = item.Assignee ? item.Assignee : item.CustomerMailID;

        }
      }
      if (item.AttachmentDetails.length) {
        item.imageArray = item.AttachmentDetails;
      }
      if (this.conversationlogData[0].position) {
        if (this.conversationlogData.length > 0 && this.conversationlogData[0].isFirst) {
          this.tempData.unshift(item);
        } else {
          this.tempData.push(item);
        }
        let indx = this.tempData.findIndex(x => x.VOCTaskManagementID === x.FirstVOCTaskManagementID);
        if (indx !== -1) {
          this.descriptionComment = { ...this.tempData[indx] };
        }
      } else {
        for (var i = 0; i <= this.tempData.length; i++) {
          if (this.tempData[i].VOCTaskManagementID === this.tempData[i].FirstVOCTaskManagementID) {
            this.descriptionComment = { ...this.tempData[i] };
          }
          if (this.tempData[i].VOCTaskManagementID == this.conversationlogData[0].VOCTaskManagementID) {
            this.tempData[i] = item;
            break;
          }
        }
      }
    });
    // this.descriptionComment = { ...(this.tempData && this.tempData.length ? this.tempData[this.tempData.length -1] : {}) }
    let _this = this;
    setTimeout(tym => {
      for (var i = 0; i < _this.tempData.length; i++) {
        if (_this.tempData[i].attachmentArray && _this.tempData[i].attachmentArray.length) {
          for (let xind = 0; xind < _this.tempData[i].attachmentArray.length; xind++) {
            //if(_this.tempData[i].attachmentArray[xind].audioType){
            //  new falconCustomAudioPlayer('.custom-audio-player' + i + xind, { showTooltips: true, showDownloadButton: false, enableKeystrokes: true });
            //}
	          if(_this.tempData[i].attachmentArray[xind].audioType){
              if(!this.isHolderExists("initial", i, xind)){
                console.log("creating "+(1+xind)+"th player for comment "+i)
                this.createPlayer("audioPlayer", xind, "initial-attachment-list-"+i, _this.tempData[i].attachmentArray[xind]);
              }
            }else{
              if(!this.isHolderExists("initial", i, xind)){
                console.log("creating "+(1+xind)+"th fileHolder for comment "+i)
                this.createFileHolder("fileHolder", xind, "initial-attachment-list-"+i, _this.tempData[i].attachmentArray[xind]);
              }
            }
              
          }
        }
      }
    }, 2000); 
  }
  mapConversation(item) {
    item.attachments.forEach(element => {
      item.totalAttachments.push(element)
    });
    item.audioAttachments.forEach(element1 => {
      item.totalAttachments.push(element1)
    });
    let copyAttachmentArray = item.totalAttachments;
    if(item.totalAttachments.length > 5) {
      item.attachmentArray =item.totalAttachments.slice(0, 5);
    }
    item.totalAttachments = copyAttachmentArray;
    item.isTranslate = false;
    item.isEdit = false;
    item.editIconEnabled = false;
    item.strTransComments = '';
    item.strLanguage = '';
    item.isActive = false;
    item.CanshowResponse = true;
    item.strComments = item.Comments.trim();
    item.commentLocalTime = moment.utc(item.CommentedDate).toDate();

    item.currentAvatar = this.getInitials(item.CommentedBy);
    if (item.UserType == 'External') {
      item.currentAvatar = '';
    }
    if (item.UserType == 'Customer' && !item.CommentedBy) {
      item.currentAvatar = '';
    }
    item.typeCss = this.setStyleBorder(item);
    item.typeAvatarCss = this.setStyleAvatar(item);
    item.typeTopCss = this.setStyleTop(item);
    if (item.TaskAction == 'Delegate') item.taskActionClass = 'delegate';
    else if (item.TaskAction == 'Transfer') item.taskActionClass = 'transfer';
    else if (item.TaskAction == 'Communicate' && !!item.customerMailID) item.taskActionClass = 'communicate';
    else if (item.TaskAction == 'Communicate') item.taskActionClass = '';
          if (item.AttachmentDetails.length) {
      item.imageArray = item.AttachmentDetails;
    }
    if (this.conversationlogData[0].position) {
      if (this.conversationlogData.length > 0 && this.conversationlogData[0].isFirst) {
        this.tempData.unshift(item);
      } else {
        this.tempData.push(item);
      }
    } else {
      for (var i = 0; i <= this.tempData.length; i++) {
        if (this.tempData[i].VOCTaskManagementID == this.conversationlogData[0].VOCTaskManagementID) {
          this.tempData[i] = item;
          break;
        }
      }
    }
  }
  showTranslate(item) {
    item.isActive = false;
    item.isEdit = false;
    item.isTranslate = !item.isTranslate;
    item.strLanguage = '';
    item.strTransComments = '';
  }

  showEdit(item, ind) {
    this.disable = !this.disable;
    item.editIconEnabled = false
    this.intFileTotal = 0;
    this.uploadedFileSize = 0;
    this.filesToUpload = [];
    this.deletedFiles = [];
    item.isTranslate = false;
    item.isEdit = !item.isEdit;
    item.Comments = item.strComments.trim();
    if (item.isEdit && (item.IsAttachmentExist || item.AttachmentDetails.length)) {
      this.ctlsTaskEditScreenService
        .getFiles({ VOCTaskManagementID: item.VOCTaskManagementID })
        .pipe(takeUntil(this.destroy$))
        .subscribe(async (res) => {
          this.LoadFile = await res.Response;
          let fileList = await res.Response['FileList'];
          item.editIconEnabled = true;
          if (this.LoadFile) {
            this.tempData.map((dataObj, index) => {
              if (index == ind) {
                this.LoadFile = _.cloneDeep(this.LoadFile);
                this.filesToUpload = this.LoadFile.FileList && this.LoadFile.FileList.length ? this.LoadFile.FileList : [];
                this.filesToUpload.map(item => {
                  item.size = item.FileSize;
                  item.name = item.FileName;
                });
                if (this.LoadFile.FileList.length) {
                  this.LoadFile.FileList.map(file => {
                    this.fileSize += file.FileSize
                    this.uploadedFileSize += file.FileSize
                  });
                }
                this.intFileTotal = this.filesToUpload != null ? this.filesToUpload.length : 0
                if (fileList.length != 0) {
                  this.uploadedFiles = [];
                  fileList.map(item => {
                    this.uploadedFiles.push(' ' + item.FileName);
                  });
                }
              }
            });
          }
        });
    } else item.editIconEnabled = true;
  }
  showAvatar(item, i) {
    item = _.cloneDeep(item)
    if (this.tempData.length) {
      this.tempData.map((data, index) => {
        if (i === index) {
          item.isActive = !item.isActive;
          data.isActive = !data.isActive;
        } else {
          data.isActive = false;
        }
      });
    }
  }
  setStyleBorder(item) {
    switch (item.UserType) {
      case 'Internal':
        return (item.css = 'd-flex history-user-wrap internal-border');
      case 'External':
        item.tooltipOther = item.CommentedBy;
        return (item.css = ' d-flex history-user-wrap other-border');
      case 'Customer':
        return (item.css = 'd-flex history-user-wrap customer-border');
      default: {
      }
    }
  }

  setStyleTop(item) {
    switch (item.UserType) {
      case 'Internal':
        return (item.css = 'user-info active internal-top');
      case 'External':
        return (item.css = ' user-info active external-top');
      case 'Customer':
        return (item.css = 'user-info active customer-top');
      default: {
      }
    }
  }
  setStyleAvatar(item) {
    switch (item.UserType) {
      case 'Internal':
        return (item.css = 'com-user-wrap internal-avatar');
      case 'External':
        item.tooltipOther = item.CommentedBy;
        return (item.css = 'com-user-wrap other-avatar');
      case 'Customer':
        return (item.css = 'com-user-wrap customer-avatar');
      default: {
      }
    }
  }
  /**
   *
   * @param string get Initials of name for avatar
   */
  getInitials(string) {
    var names = string.split(' '),
      initials = names[0].substring(0, 1).toUpperCase();
    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  }

  /**
   * Transalte comment
   */
  translate(item) {
    item.Comments = item.Comments.trim()
    if (!!item.Comments) {
      let objData = {
        LanguageId: item.strLanguage.LangID,
        TextToTranslate: item.Comments,
      };
      this.ctlsTaskEditScreenService
        .getTranslateWithGoogle(objData)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          if (res.Response) {
            item.strTransComments = res.Response.TranslatedText;
          }
        });
    }
  }

  /**Open preview for uplodaed files
   * @param data
   */
  openPreview(item) {
    if (!this.openDialogDwld) {
    this.openDialogDwld = true;
    this.ctlsTaskEditScreenService
      .GetAttachments(item.VOCTaskManagementID)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res.Response) {
          var filesAttachment = res.Response;
          item.isActive = false;
          const ref = this.dialogService.open(ImagePreviewVocComponent, {
            styleClass: 'attachment-popup-width',
            height: '50vh',
            transitionOptions: '0ms',
            data: filesAttachment
          });
          ref.onClose.pipe(takeUntil(this.destroy$)).subscribe((data) => { 
            this.openDialogDwld = false;
          });
        }
      });
    }
  }

  showAudioFiles(filesAttachment) {
    const ref = this.dialogService.open(AudioPreviewComponent, {
      styleClass: 'attachment-popup-width',
      height: '50vh',
      transitionOptions: '0ms',
      data: filesAttachment,
    });
  }
  
  downloadImg(img) {
    this.ctlsTaskEditScreenService
      .GetFileUrl(img.VOCTaskFilesListID)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res.Response) {
          const downloadLink = document.createElement('a');
          downloadLink.href = res.Response;
          downloadLink.download = img.FileName;
          downloadLink.click();
        }
      });
  }

  scroll() {
    this.conversationlogData.map((data, index) => {
      data.isActive = false;
    });
    for (var i = 0; i <= this.tempData.length; i++) {
      if (this.tempData[i].IsCommentEditable != undefined) {
        this.IsCommentEditRequired = false;
        this.skipCount = this.skipCount + 6;
        let dataObj = {
          skipCount: this.skipCount,
          requiredBoolean: this.IsCommentEditRequired,
        }
        this.eventFromChatHistory.emit({
          data: dataObj,
          operation: 'LOAD_CHAT_HISTORY',
        });
        return;
      }
      else {
        this.IsCommentEditRequired = true
      }
    }

    this.skipCount = this.skipCount + 6;
    let dataObj = {
      skipCount: this.skipCount,
      requiredBoolean: this.IsCommentEditRequired,
    }
    this.eventFromChatHistory.emit({
      data: dataObj,
      operation: 'LOAD_CHAT_HISTORY',
    });
  }
  // *************************************image and files handling****************************

  /**
   * Select files
   */
  @ViewChild('attach')
  attach: ElementRef;
  selectFiles(event) {
    let selectedFiles = (event.target as HTMLInputElement).files;
    selectedFiles = _.orderBy(selectedFiles, ['size'], ['asc']);
    Array.from(selectedFiles).forEach((file) => {
      if (!!this.isValidFile(file)) {
        if (this.checkFileSize(file)) {
          this.filesToUpload.push(file);
          this.filesToUpload = this.filesToUpload.filter(
            (thing, index, self) =>
              index ===
              self.findIndex(
                (t) => t.size === thing.size && t.name === thing.name
              )
          );
          this.convertToBase64(file, 'file');
        } else {
          return;
        }
      } else {
        this.strMaxSizeError = 'Invalid file format';
        return;
      }
    });

    if (event.target.files && event.target.files[0]) {
      // if (this.filesToUpload.length > 5) {
      //   this.strMaxSizeError = 'Number of attachments cannot exceed 5.';
      //   this.filesToUpload.splice(5, this.filesToUpload.length - 1);
      //   this.intFileTotal = this.filesToUpload.length;
      // }
      if (this.filesToUpload.length) {
        this.filesToUpload = _.orderBy(this.filesToUpload, ['size'], ['asc']);
      }
      this.uplodedFiles = this.filesToUpload;
      this.uploadedFiles = [];
      this.filesToUpload.map(item => {
        if (!!item.name) {
          this.uploadedFiles.push(' ' + item.name);
        } else {
          this.uploadedFiles.push(' ' + item.FileName);
        }

      });
      this.hideShowFilesizeMessage();
    }
  }

  checkFileSize(file) {
    if (file.size <= 20971520 - this.getFileSize()) {
      return true;
    } else {
      this.strMaxSizeError = 'Total size of upload/s cannot exceed 20MB';
      //  this.clearErrorMsg();
      return false;
    }
  }

  getFileSize() {
    let intByteSize = 0;
    const concatedArray = [...this.filesToUpload];
    for (let i = 0; i < concatedArray.length; i++) {
      if (concatedArray[i].size) {
        intByteSize += concatedArray[i].size;
      }
      intByteSize += this.uploadedFileSize
    }
    return intByteSize;
  }

  private convertToBase64(file: File, type: string) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (type === 'file') {
        this.base64Files.push({ URL: reader.result });
      }
    };
    reader.onerror = (error) => { };
  }

  private hideShowFilesizeMessage() {
    let intByteSize = 0;
    const concatedArray = [...this.filesToUpload];

    for (let i = 0; i < concatedArray.length; i++) {
      intByteSize += concatedArray[i].size;
    }
    this.checkFormatFileSize(intByteSize, 2);
    if (
      this.strMaxSizeError === 'Total size of upload/s cannot exceed 20MB' ||
      this.strMaxSizeError === 'Number of attachments cannot exceed 5.' ||
      this.strMaxSizeError === 'Invalid file format'
    ) {
      this.clearErrorMsg();
    } else {
      this.strMaxSizeError = '';
    }
  }

  private clearErrorMsg() {
    setTimeout(() => {
      this.strMaxSizeError = '';
      this.ref.detectChanges();
    }, 5000);
  }

  deleteTrigger(index, type: string) {
    this.filesToUpload = _.cloneDeep(this.filesToUpload);
    if (type === 'file') {
      if (this.filesToUpload[index].FileSize)
        this.uploadedFileSize -= this.filesToUpload[index].FileSize
      let deletedFile: any = this.filesToUpload.splice(index, 1);
      if (deletedFile && deletedFile.length && deletedFile[0].VOCTaskFilesListID) {
        this.deletedFiles.push(deletedFile[0].VOCTaskFilesListID);
      }
      this.intFileTotal = this.filesToUpload.length;
      this.uploadedFiles.splice(index, 1);
    }
    this.hideShowFilesizeMessage();
  }

  /**
   * check file size above 20 mb
   */
  checkFormatFileSize(bytes, decimalPoint) {
    if (bytes == 0) return false;
    let k = 1000,
      dm = decimalPoint || 2,
      sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    if (sizes[i] === 'MB') {
      if (parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) > 20.97) {
        this.clearErrorMsg();
        return true;
      } else {
        this.intFileTotal = this.filesToUpload.length;
        return false;
      }
    } else {
      this.intFileTotal = this.filesToUpload.length;
      return false;
    }
  }

  /**
   * vaildate file type
   */
  isValidFile(file) {
    if (
      file.name.toLowerCase().endsWith('.msg') ||
      file.name.toLowerCase().endsWith('.doc') ||
      file.name.toLowerCase().endsWith('.docx') ||
      file.name.toLowerCase().endsWith('.pdf') ||
      file.name.toLowerCase().endsWith('.xls') ||
      file.name.toLowerCase().endsWith('.xlsx') ||
      file.name.toLowerCase().endsWith('.ppt') ||
      file.name.toLowerCase().endsWith('.pptx') ||
      file.name.toLowerCase().endsWith('.jpg') ||
      file.name.toLowerCase().endsWith('.jpeg') ||
      file.name.toLowerCase().endsWith('.png') ||
      file.name.toLowerCase().endsWith('.tiff') ||
      file.name.toLowerCase().endsWith('.bmp')||
      file.name.toLowerCase().endsWith('.mp3') ||
      file.name.toLowerCase().endsWith('.ogg') ||
      file.name.toLowerCase().endsWith('.wav') ||
      file.name.toLowerCase().endsWith('.m4a') ||
      file.name.toLowerCase().endsWith('.aac')
      // .doc, .docx, .pdf, .msg, .xls, .xlsx
    ) {
      return true;
    }
  }
  /**
   * clear all uploaded files
   */
  removeAllUplodedFile() {
    this.attach.nativeElement.value = '';
    this.strMaxSizeError = '';
    this.intFileTotal = 0;
    this.base64Files = [];
    this.uploadedFileSize -= this.fileSize;
    this.fileSize = 0;
    this.uploadedFiles = [];
    while (this.filesToUpload && this.filesToUpload.length) {
      let deletedFile: any = this.filesToUpload.splice(0, 1);
      if (deletedFile && deletedFile.length && deletedFile[0].VOCTaskFilesListID) {
        this.deletedFiles.push(deletedFile[0].VOCTaskFilesListID);
      }
    }
  }


  /**
   * update comment 
   * */
  updateComent(item, ind) {
    item.Comments = item.Comments.trim()
    this.disable = !this.disable;
    this.frmData = new FormData();
    item = _.cloneDeep(item)
    let dataObj = {
      VOCTaskManagementID: item.VOCTaskManagementID,
      Comment: item.Comments ? item.Comments : '',
      VOCTaskFilesListID: this.deletedFiles,
    };
    if (this.filesToUpload.length) {
      this.filesToUpload.map(item => {
        if (!item.VOCTaskFilesListID && item.name) {
          this.frmData.append('files', item);
        }
      });
    }
    this.frmData.append('data', JSON.stringify(dataObj));
    this.showRemainingAttachmtsFlag[ind] = false;
    //validation
    if (dataObj.Comment === '') {
      let data = {
        message: 'Comment cannot be blank.',
        type: 'RequiredComment',
      };
      this.openPopUp(data);
      setTimeout(() => {
        this.comment.nativeElement.focus();
      }, 0);
      return;
    }
    this.eventFromChatHistory.emit({
      data: this.frmData,
      operation: 'UPDATE_COMMENT',
    });
  }


  //validation pop up
  openPopUp(data) {
    if (data) {
      this.eventFromChatHistory.emit({
        data: data,
        operation: 'OPEN_POPUP',
      });
    }
  }

  //resend mail
  showResend(item) {
    if (!this.disable) {
      this.disable = !this.disable
    }
    const objSavedata = {
      VOCTaskListID: this.selectedTask ? this.selectedTask.VOCTaskListID : '',
      VOCTaskManagmentID: item ? item.VOCTaskManagementID : ''
    };
    this.spinner.show();
    this.ctlsTaskEditScreenService
      .getResendTransferTaskDetails(objSavedata)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if (!!data.Response) {
          this.tempData = [];
          this.resendObj = data.Response;
          this.skipCount = 0;
          let dataObj = {
            skipCount: this.skipCount,
            requiredBoolean: undefined,
          }
          let dataPopup = {
            message: 'The communication has been resent successfully.',
            type: 'ResendSuccess',
          };

          this.openPopUp(dataPopup);
          this.eventFromChatHistory.emit({
            data: dataObj,
            operation: 'LOAD_CHAT_HISTORY',
          });
          this.ref.detectChanges();
        } else {
          this.spinner.hide();
        }
      });
  }
  toggleView() {
    this.collapseData = !this.collapseData;
  }
  showRemainingAttachments(item, commentIndex) {
    this.moreLoading = true;
    if (!(this.showRemainingAttachmtsFlag && this.showRemainingAttachmtsFlag[commentIndex])) {
      this.showRemainingAttachmtsFlag = {};
      this.showRemainingAttachmtsFlag[commentIndex] = true;

    item.listAttachmentArray = [];
    item.listAttachmentArray = item.totalAttachments.slice(5);
    setTimeout(tym => {
      this.moreLoading = false;
    if (item.listAttachmentArray && item.listAttachmentArray.length) {
      for (let xind = 0; xind < item.listAttachmentArray.length; xind++) {
        if(item.listAttachmentArray[xind].audioType){
          if(!this.isHolderExists("more", commentIndex, xind)){
            console.log("creating "+(1+xind)+"th player for comment "+commentIndex)
            this.createPlayer("audioPlayer", xind, "more-attachment-list-"+commentIndex, item.listAttachmentArray[xind]);
          }
        }else{
          if(!this.isHolderExists("more", commentIndex, xind)){
            console.log("creating "+(1+xind)+"th fileHolder for comment "+commentIndex)
            this.createFileHolder("fileHolder", xind, "more-attachment-list-"+commentIndex, item.listAttachmentArray[xind]);
          }
        }
          
      }
    }
  }, 1000);
} else {
  this.showRemainingAttachmtsFlag[commentIndex] = false;
}
  }
 
  @HostListener('document:click', ['$event']) documentClickEvent($event: any) {   
    let el = document.getElementById('audio-attachment-holder');
    let el2: any = document.getElementsByClassName('audio_icon');  
    if ((el && el.contains($event.target))) {
      console.log('Clicked');
    } else {
      let flag = false;
      if (el2 && el2.length) {
        for(let item of el2) {
          if(item.contains($event.target)) {
            flag = true;
          }
        }
        if (!flag) {
          this.showAudioDialog = {};
        }
      } else {
      this.showAudioDialog = {};
      }
    }   
  }

  createPlayer(palyerPrefix, audioIndex, playerContainerId, audioData) {
     console.log(playerContainerId)
     let _this = this;
     const playerContainer = document.querySelector('#' + playerContainerId) as HTMLElement;
     const playerId = playerContainerId + palyerPrefix + (audioIndex + 1);
     const recording = document.createElement('div')
     recording.id = playerId;
     recording.style.display = "flex";
     recording.style.height = "35px";
     recording.style.justifyContent = "space-evenly";
     recording.style.alignItems = "center";
     recording.style.border = "1px solid #AFC6D4"
     recording.style.background = "#EEF8FF"
     recording.style.borderRadius = "30px"
     const container = document.createElement('div')
     container.id = playerId + "Wave";
     container.onclick = (event) => {
       event.stopPropagation();
     }
     recording.appendChild(container)
  
     if(playerContainerId.includes('initial')){
      let playerContainerHolder = document.querySelector('#' +playerContainerId.replace("initial", "more")+"-holder");
      playerContainer.insertBefore(recording, playerContainerHolder)
     }else{
      playerContainer.appendChild(recording)
     }
     let audioUrl = "";
     let waveWidth = "100";
     if (audioData.URL) {
       audioUrl = audioData.URL;
     }

     // Create wavesurfer from the recorded audio
     const wavesurfer = WaveSurfer.create({
       container,
       waveColor: '#b1b1b1',
       progressColor: '#c6c6c6',
       height: 28,
       barWidth: 2,
       barHeight:0.5,
       minPxPerSec: 1,
       width: waveWidth,
       url: audioUrl,
     })
  
     // Play-Pause button
     const button = document.createElement('button');
     button.id = playerId + "PlayBtn";
     button.style.width = "10px";
     button.style.height = "24px";
     button.style.background = "transparent";
     button.style.border = "none";
     button.style.padding = "0";
     button.style.display = "flex";
     button.style.alignItems = "center";
  
  
     // Create an image element
     var imagePlay = document.createElement("img");
  
     // Set image source
     imagePlay.src = this.imagepath + 'VOC-Audio/play-button.svg'; // Add the URL of your image here
  
     // Set alt text for accessibility
     imagePlay.alt = "Play";
  
     // Create an image element
     var imagePause = document.createElement("img");
  
     // Set image source
     imagePause.src = this.imagepath + 'VOC-Audio/pause-button.svg'; // Add the URL of your image here
  
     // Set alt text for accessibility
     imagePause.alt = "Pause";    
  
     // Append the image to the button
     button.appendChild(imagePlay);
  
     button.onclick = (event) => {
       event.stopPropagation();
       wavesurfer.playPause();
     }
  
     recording.insertBefore(button, container)
  
     wavesurfer.on('pause', () => {
       button.removeChild(imagePause);
       button.appendChild(imagePlay);
     })
  
     wavesurfer.on('play', () => {
       button.removeChild(imagePlay);
       button.appendChild(imagePause);
     })
  
     const duration = document.createElement('div')
     duration.id = playerId + "Duration";
     duration.onclick = (event) => {
       event.stopPropagation();
     }
     recording.appendChild(duration)
  
     wavesurfer.on('ready', () => {
       duration.innerText = this.formatTime(wavesurfer.getDuration()*1000);
     })
  
     // Download button
     const buttonDownload = document.createElement('button');
     buttonDownload.id = playerId + "DownloadBtn";
     buttonDownload.style.width = "24px";
     buttonDownload.style.height = "24px";
     buttonDownload.style.background = "transparent";
     buttonDownload.style.border = "none";
     buttonDownload.style.display = "flex";
     buttonDownload.style.alignItems = "center";
     buttonDownload.style.borderRadius = "50%";
     buttonDownload.style.justifyContent = "center";
     buttonDownload.style.padding = "4px";
  
  
     // Create an image element
     var imageDelete = document.createElement("img");
  
     // Set image source
     imageDelete.src = this.imagepath + 'VOC-Audio/audio-download.svg'; // Add the URL of your image here
  
     // Set alt text for accessibility
     imageDelete.alt = "Delete";
  
     imageDelete.style.height = "100%";
  
     // Append the image to the button
     buttonDownload.appendChild(imageDelete);
  
     buttonDownload.onclick = (event) => {
       console.log("palyerPrefix=>", palyerPrefix)
       event.stopPropagation();
       this.downloadImg(audioData);
  
     }
  
     recording.insertAdjacentElement("beforeend", buttonDownload)
  
     const audioLabel = document.createElement('div');
     audioLabel.style.color = "#2089ad";
     audioLabel.style.fontSize = "smaller";
     audioLabel.style.width = "auto";
     audioLabel.style.right = "15px";
     audioLabel.style.position = "absolute";
     audioLabel.id = playerId + "Label";
     audioLabel.innerText = audioData.FileName;
     recording.insertAdjacentElement("beforeend",audioLabel)
  
    // Filename button
    const buttonFilename = document.createElement('button');
    buttonFilename.id = playerId + "FilenameBtn";
    buttonFilename.style.width = "18px";
    buttonFilename.style.height = "37px";
    buttonFilename.style.background = "#fff";
    buttonFilename.style.border = "none";
    buttonFilename.style.display = "flex";
    buttonFilename.style.alignItems = "center";        ;
    buttonFilename.style.position = "relative";
    buttonFilename.style.right = "-3px";

     // Create an image element
     var imageFilename = document.createElement("img");
  
     // Set image source
     imageFilename.src = this.imagepath + 'voc-listing/filename-link.svg'; // Add the URL of your image here
  
     // Set alt text for accessibility
     imageFilename.alt = "";
  
     imageFilename.style.height = "13px";
     imageFilename.style.position = "absolute";
     imageFilename.style.left = "0";
     imageFilename.style.right = "2px";
     imageFilename.style.margin = "0 auto";

  
     // Append the image to the button
     buttonFilename.appendChild(imageFilename);

    buttonFilename.onmouseover = (event) => {
      event.stopPropagation();
     this.showFilename(audioIndex, playerId)
 
    }

    buttonFilename.onmouseleave = (event) => {
      console.log("palyerPrefix=>", palyerPrefix)
      event.stopPropagation();
     this.hideFilename(audioIndex, playerId)
 
    }

    recording.insertAdjacentElement("beforeend",buttonFilename)

   }

   showFilename = (audioIndex, playerId)=>{
    let filenameLabel = document.getElementById(playerId + "Label");
    filenameLabel.style.display = "block";
   };

   hideFilename = (audioIndex, playerId)=>{
    let filenameLabel = document.getElementById(playerId + "Label");
    filenameLabel.style.display = "none";
   };
  
  // Format time as HH:MM:SS
  formatTime = (milliseconds) => {
    const seconds = Math.ceil(milliseconds / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);

    // const formattedTime =
    //   this.pad(hours % 24) + ':' +
    //   this.pad(minutes % 60) + ':' +
    //   this.pad(seconds % 60);

    const formattedTime =
      minutes % 60 + ':' +
      this.pad(seconds % 60);

    return formattedTime;
  }

  // Add leading zero if needed
  pad = (number) => {
    return ('0' + number).slice(-2);
  }

  createFileHolder(palyerPrefix, audioIndex, playerContainerId, audioData) {
    console.log(playerContainerId)
    let _this = this;
    const playerContainer = document.querySelector('#' + playerContainerId) as HTMLElement;
    const playerId = playerContainerId + palyerPrefix + (audioIndex + 1);
    const fileHolder = document.createElement('div')
    fileHolder.id = playerId;   
    fileHolder.classList.add('attachment-file-holder');
    fileHolder.classList.add(audioData.extension);

    const fileName = document.createElement('p')
    fileName.id = playerId + "Name";
    fileName.innerText = audioData.FileName;
    fileHolder.appendChild(fileName)

    const fileLink = document.createElement('a')
    fileLink.id = playerId + "Link";
    fileLink.onclick = (event) => {
      event.stopPropagation();
      this.downloadImg(audioData);
    }

    fileLink.classList.add('attachmentDownload');
    
    // Create an image element
    var imageFileLink = document.createElement("img");
  
    // Set image source
    imageFileLink.src = this.imagepath + 'voc-listing/download-attachment.svg'; // Add the URL of your image here
 
    // Set alt text for accessibility
    imageFileLink.alt = "";

    fileLink.appendChild(imageFileLink)
    fileHolder.appendChild(fileLink)
 
    if(playerContainerId.includes('initial')){
     let playerContainerHolder = document.querySelector('#' +playerContainerId.replace("initial", "more")+"-holder");
     playerContainer.insertBefore(fileHolder, playerContainerHolder)
    }else{
     playerContainer.appendChild(fileHolder)
    }
  }

  isHolderExists = (type, ind, xind) => {
    let initialElement = document.getElementById(type+"-attachment-list-"+ind);
    const nodeList = initialElement.children;
    console.log(type+"-attachment-list-"+ind+" contains nodeList=>", nodeList)
    let exists = false;
    for(let i=0; i<=nodeList.length;i++){
      if(nodeList[i]){
        console.log(nodeList[i].id, "<==", 'audioPlayer'+(1+xind))
        if(nodeList[i].id.includes('audioPlayer'+(1+xind))||nodeList[i].id.includes('fileHolder'+(1+xind))){
          exists = true;
          break;
        }
      }
    }
    console.log('exists=', exists);
    return exists;
  }
}

