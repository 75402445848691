export class CONSTANTS {
    public static taskType = {
        COMPLIMENT: 'Compliment',
        COMPLAIN: 'Complaint',
        ENQUIRY: 'Enquiry',
        OTHERS: 'Others',
        UPDATE: 'Update'
    }
    public static taskMode = {
        OTHERS: 'Others'
    }
    public static AccountType = {
        STANDARD: 'STANDARD',
        GLOBAL: 'GLOBAL',
        COMPANY: 'COMPANY'
    }
    public static headerVal = {
        VOCTaskID: 'ID',
        Type: 'Type',
        RecievedDate: 'Received Date',
        CreatedDate: 'Created Date',
        RecievedBy: 'Received By',
        Assignee: 'Assignee',
        FAM: 'FAM',
        Description: 'Description',
        Others: 'Other Task Type',
        CreatedBy: 'Created By',
        ModifiedBy: 'Modified By',
        ModifiedDate: 'Modified Date',
        TaskCreatedDate: 'Task Created Date',
        Name: 'Customer',
        ContactName: 'Contact Name',
        Comment: 'Comments'
    }
    public static statusCode = {
        RSLVD: 'RSLVD'
    }
    public static status = {
        Open: 'Open'
    }
}