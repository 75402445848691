import { Output,HostListener, ViewChild } from '@angular/core';
import { SimpleChanges, OnChanges } from '@angular/core';
import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';
import { CtlsVocEditScreenService } from '../../ctls-services/ctls-voc-edit-screen.service';
import { CtlsVocListingService } from '../../ctls-services/ctls-voc-listing.service';
import { CONSTANTS } from '../../../ctls-pages/ctls-voc-listing/constants/ctls-voc-constant';
import { UserManagementService } from 'src/app/components/falcon-setup/user-management/services/user-management.service';

@Component({
  selector: 'app-ctls-information-voc-section',
  templateUrl: './ctls-information-section.component.html',
  styleUrls: ['./ctls-information-section.component.scss'],
})
export class CtlsInformationSectionComponent implements OnInit, OnChanges {
  @Output()
  eventFromInformation: EventEmitter<any> = new EventEmitter<any>();
  @Input() title: any;
  @Input() selectedTask: any;
  @Input() saveRequestBodyObj: any;
  @Input() statusEditable: any;
  @Input() collapseStatus: any;
  @Input() disableEDIT: any;
  @Input() collapseData: any;
  @Input() objAssignees: any;

  @ViewChild('assigneeDDL') assigneeDDL; 
  taskTitle: String;
  selectedStatus: any;
  closedStatus = {
    // for disable / enable catgory check
    StatusCode: 'CLSD',
    StatusDescription: 'Closed',
  };
  vocConstant = CONSTANTS;
  destroy$ = new Subject();
  IsDeleteEnable = false;
  disableFlag = false;
  isShow = false;
  loadAllID = false; // for check get all loaded

  // Master data properties
  data = [];
  brand = [];
  savedFilterObject = [];
  // for dropdown options
  famData = [];
  baData = [];
  divData = [];
  brandData = [];

  // selected filter
  filterMapping: any = {
    FAMId: null,
    BAId: null,
    DIVId: null,
    BrandId: null
  };

  dataOriginal = [];
  brandOriginal = [];
  customerNameList: any = [];
  resetGlobalFilterData() {
    this.dataOriginal = [];
    this.brandOriginal = [];
  }
  
  @ViewChild('famDDL') famDDL;
  @ViewChild('baDDL') baDDL;
  @ViewChild('divDDL') divDDL;
  @ViewChild('brandDDL') brandDDL;

  selectedCount = {
    baCount: [],
    divCount: [],
    famCount: [],
    brandCount: []
  }
  assigneeData: any = {};
  allowBAFAMEdit = false;
  custNameData = null;
  custNoData = null;
  constructor(public router: Router,
    private ctlsTaskEditScreenService: CtlsVocEditScreenService,
    private ctlsVocListingService: CtlsVocListingService,
    private userManagementService: UserManagementService) { }

  ngOnInit() {
    
    /* this.selectedTask.BA = '';
    this.selectedTask.FAM = '';
    this.selectedTask.Brand = '';
    this.selectedTask.DIV = '';
    this.selectedTask.BAID = '';
    this.selectedTask.FAMID = '';
    this.selectedTask.DIVID = '';
    this.selectedTask.BrandID = '';
    this.selectedTask.DivIds = [];
    this.selectedTask.BrandIds = [];
    this.selectedTask.Assignee = '';
    this.selectedTask.AssigneeID = ''; 
    this.selectedTask.CustomerName = '';
    this.selectedTask.CustomerAccountNo = ''; */
    this.getUsers();
    this.userManagementService.getUserDetails().then(res => {
      if (res && res.Status === 'Success') {
        this.allowBAFAMEdit = res.Response.SelectedRoles && res.Response.SelectedRoles.length && res.Response.SelectedRoles.find(x => x.RoleCode === 'SUPER_ADMIN') ? true : false;
        /* if (!this.selectedTask.EntityId && this.allowBAFAMEdit) {
          this.getCustomerList();
        } */
        if (!(this.selectedTask.BAID && this.selectedTask.FAMID && this.selectedTask.DivIds &&
          this.selectedTask.DivIds.length && this.selectedTask.BrandIds && this.selectedTask.BrandIds.length) && this.allowBAFAMEdit) {
          this.getMasterData();
        }
      }
    });
  }
  /**
   * On Changes
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.title) {
      this.taskTitle = this.title;
    }

    if (changes.selectedTask) {
      if (this.selectedTask && this.selectedTask.CurrentStatus) {
        this.selectedStatus = this.selectedTask.CurrentStatus;
      }
    }
    if (changes.objAssignees) {
      _.remove(this.objAssignees, ele => ele.AssigneeID === this.selectedTask.AssigneeID);
    }
  }
  /**
   * Request for Toggle => Emits event to Container
   * operation: TOGGLE_CLICK
   */
  toggleEvent() {
    this.collapseStatus = !this.collapseStatus;
    this.eventFromInformation.emit({
      operation: 'TOGGLE_CLICK',
      data: { collapseStatus: this.collapseStatus },
    });
  }
  toggleView() {
    this.collapseData = !this.collapseData;
  }

  /**
   * auto complete select box for assignees in the tarnasfer section
   * @param event
   */
  filteredUser: any[];
  filterUser(event) {
    //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
    let filtered: any[] = [];
    let query = event.query;
    for (let i = 0; i < this.objAssignees.length; i++) {
      let user = { ...this.objAssignees[i] };
      if (user.Assignee.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(user);
      }
    }
    this.filteredUser = filtered;
  }

  onDropDownClick(event: any) {
    this.isShow = !this.isShow;
    if (!this.isShow) this.filteredUser = [];
  }

  /**
   * 
   * @param event 
   */
  setAssignee(event) {
    this.saveRequestBodyObj.AssigneeID = event ? event.AssigneeID : null;
    this.saveRequestBodyObj.Assignee = event ? event.Assignee : null;
    this.eventFromInformation.emit({
      operation: 'ASSIGNEE_UPDATE',
      data: this.saveRequestBodyObj,
    });
  }
  /**
   * getUsers
   */
  getUsers() {
    this.objAssignees = [];
    let dataObj = {
      FAMID: parseInt(this.saveRequestBodyObj && this.saveRequestBodyObj.FAMId ? this.saveRequestBodyObj.FAMId : this.selectedTask.FAMID),
      BAID: parseInt(this.saveRequestBodyObj && this.saveRequestBodyObj.BAId ? this.saveRequestBodyObj.BAId : this.selectedTask.BAID)
    };  
    if (!(this.filterMapping.BAId && this.filterMapping.FAMId)) {
      this.filterMapping.BAId = this.selectedTask.BAID ? Number(this.selectedTask.BAID) : null;
      this.filterMapping.FAMId = this.selectedTask.FAMID ? Number(this.selectedTask.FAMID) : null;
      this.filterMapping.DIVId = this.selectedTask.DivIds;
      this.filterMapping.BrandId = this.selectedTask.BrandIds;
    }
    this.eventFromInformation.emit({
      data: dataObj,
      operation: 'FETCH_ASSIGNEE'
    });
    /**
     * Dropdown API
     */
   /*  this.ctlsTaskEditScreenService
      .GetAllEnabledAssignees(dataObj)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.objAssignees = _.uniqBy(res.Response, 'Assignee');
        _.remove(this.objAssignees, ele => ele.AssigneeID === this.selectedTask.AssigneeID);
      }); */
  }
  showAccountDetails(item) {
    // if (this.selectedTask.SurveyType === 'TRANS') {
      this.router.navigate(['ctls/360/customers'], {
        queryParams: {
          EntityID
            : item.EntityId,
            isDistributor: item.EntityType === 'DIST' ? true : false
        },
        // skipLocationChange: true,
      });
    /* }
    else {
      this.router.navigate(['ctls/360/distributors'], {
        queryParams: {
          EntityID: item.EntityId,
          isDistributor: true
        },
        // skipLocationChange: true,
      });
    } */
  }
  showContactDetails(item) {
    this.router.navigate(['ctls/360/customers'], {
      queryParams: {
        ContactID: item.ContactId,
        TaskID: item.VOCTaskListID,
        EntityID: item.EntityId
      },
      // skipLocationChange: true,
    });
  }
  @HostListener('scroll', ['$event']) scrollHandler(event) {  
    if(event && event.target){
      event.target.click();
    }       
  }


  getMasterData() {
    let masterObj = this.ctlsVocListingService.getVocEditMasterDataSession();
    if (_.isEmpty(masterObj)) {
    this.ctlsTaskEditScreenService.getMasterData()
      .pipe(takeUntil(this.destroy$))
      .subscribe((rawdata: any) => {
        if (!_.isEmpty(rawdata)) {
          this.ctlsVocListingService.setMasterDataSession({ FamBADivBLFilterModel: rawdata.FamBADivBLFilterModel, FamBABrandFilterModel: rawdata.FamBABrandFilterModel });
          if (rawdata.FamBADivBLFilterModel) {
            this.dataOriginal = Object.assign(
              rawdata.FamBADivBLFilterModel
            );
            this.brandOriginal = Object.assign(
              rawdata.FamBABrandFilterModel
            );
            this.getViewData();
          }
        }
      }, () => { });
    } else {
      if (!_.isEmpty(masterObj)) {
        if (masterObj.FamBADivBLFilterModel) {
          this.dataOriginal = Object.assign(
            masterObj.FamBADivBLFilterModel
          );
          this.brandOriginal = Object.assign(
            masterObj.FamBABrandFilterModel
          );
          this.getViewData();
        }
      }
    }
  }
  
  getSearchReq(actSearchStr = null) {
    let dataObj = {
      FAMID: parseInt(this.saveRequestBodyObj && this.saveRequestBodyObj.FAMId ? this.saveRequestBodyObj.FAMId : this.selectedTask.FAMID),
      BAID: parseInt(this.saveRequestBodyObj && this.saveRequestBodyObj.BAId ? this.saveRequestBodyObj.BAId : this.selectedTask.BAID),
      DivIds: this.selectedTask.DivIds && this.selectedTask.DivIds.length ? this.selectedTask.DivIds : (this.saveRequestBodyObj.DivIds && typeof this.saveRequestBodyObj.DivIds === 'string' ? this.saveRequestBodyObj.DivIds.split(',') : []),
      BrandIds: this.selectedTask.BrandIds && this.selectedTask.BrandIds.length ? this.selectedTask.BrandIds : (this.saveRequestBodyObj.BrandIds && typeof this.saveRequestBodyObj.BrandIds === 'string' ? this.saveRequestBodyObj.BrandIds.split(',') : [])
    };
    return {
      pageNumber: 1,
      pageSize: 10,
      filterFields: {
        fAMIDs: [dataObj.FAMID].every(item => item === 0) ? [] : [dataObj.FAMID].map(Number),
        bAIDs: [dataObj.BAID].every(item => item === 0) ? [] : [dataObj.BAID].map(Number),
        dIVIDs: [],
        contientIDs: [],
        countryIDs: [],
        operationalCountryIDs: [],
        categoryIDs: [],
        touchPointIDs: [],
        brandIDs: [],
        branchIDs: [],
        zoneIDs: [],
        influencers: [],
        regionIDs: [],
        salesEngineers: [],
        serviceTechnicians: [],
        customerNames: [],
        customerAccountNumbers: [],
        fromDate: '',
        toDate: '',
        periodType: '',
        surveyNameID: null,
        surveyScheduleInstanceID: null,
        distributors: [],
        distributorSegment: [],
        distributorAccountNumber: [],
        surveyID: '',
        contactName: '',
        orderNo: '',
        nPSScore: '',
        nPSComment: '',
        influenceLevel: '',
        emailAddress: '',
        objective: '',
        event: '',
        category: '',
        distributorshipManagerSales: '',
        distributorshipManagerService: '',
        modifiedBy: '',
        comments: '',
        taskCount: '',
        cTLSEnabledDate: '',
        cTLSModifiedDate: '',
        feedbackSubmitDate: '',
        firstName: '',
        lastName: '',
        emailID: '',
        designation: '',
        Status: '',
        AccountSearch: actSearchStr
      },
      sortType: '',
      sortField: '',
      GlobalEntityID: null,
      StandardEntityID: null,
      EntityID: null,
      ContactID: null,
      AccountNo: '',
      CustomerName: '',
      GlobalEntityName: '',
      GcCode: '',
      CustomerType: 'CUST'
    };
  }
  getCustomerList(event, field) {
    // if (!this.selectedTask.CustomerName || !this.selectedTask.CustomerAccountNo || this.selectedTask.CustomerAccountNo == '0') {
      this.ctlsTaskEditScreenService.getCustomerList(this.getSearchReq(event.query))
        .pipe(takeUntil(this.destroy$))
        .subscribe(resData => {
          if (resData && resData.Status === 'Success') {
            this.customerNameList = resData.Response;
          } else {
            this.customerNameList = [];
          }
        }, () => { });
    // }
  }

  SelectedCustNameActNo(field) {
    let dat = this.customerNameList.find(x => x.EntityId === (field === 'CustomerName' ? this.custNameData.EntityId : this.custNoData.EntityId));
    this.saveRequestBodyObj.CustomerName = dat ? dat.CustomerName : null;
    this.saveRequestBodyObj.AccountNumber = dat ? dat.AccountNumber: null;
    this.saveRequestBodyObj.EntityId = dat ? dat.EntityId: null;
    field === 'CustomerName' ? (this.custNoData = this.custNameData || null) : (this.custNameData = this.custNoData || null);
  }

  removeExtraFromSaveObj() {
    var famids = [];
    var baids = [];
    var divids = [];
    var brandids = [];
    if (this.filterMapping.FAMId != null) {
      let dat = this.famData.find(e => e.value === this.filterMapping.FAMId);
      famids.push(dat.value);
    }

    if (this.filterMapping.BAId) {
      let dat = this.baData.find(e => e.value === this.filterMapping.BAId);
      baids.push(dat.value);
    }
    if (this.filterMapping.DIVId != null && this.filterMapping.DIVId.length > 0) {
      this.filterMapping.DIVId.forEach(element => {
        if (this.divData.map(ele => { return ele.value }).indexOf(element) >= 0) {
          divids.push(element);
        }
      });
    }

    if (this.filterMapping.BrandId != null && this.filterMapping.BrandId.length > 0) {
      this.filterMapping.BrandId.forEach(element => {
        if (this.brandData.map(ele => { return ele.value }).indexOf(element) >= 0) {
          brandids.push(element);
        }
      });
    }
    this.saveRequestBodyObj.FAMId = famids && famids.length ? famids[0] : 0;
    this.saveRequestBodyObj.BAId = baids && baids.length ? baids[0] : 0;
    this.saveRequestBodyObj.DivIds = divids;
    this.saveRequestBodyObj.BrandIds = brandids;
    this.saveRequestBodyObj.CustomerName = null;
    this.saveRequestBodyObj.AccountNumber = null;
    this.saveRequestBodyObj.EntityId = null;
    this.custNameData = null;
    this.custNoData = null;
  }
  
  // Filter based on FAM
  SelectedFams(event, setValueFlag = false) {
    this.clearAssigneeDetails();
    if (setValueFlag) {
      this.removeExtraFromSaveObj();
      this.eventFromInformation.emit({
        operation: 'BA_DETAILS_UPDATE',
        data: this.saveRequestBodyObj,
      });
      if (this.saveRequestBodyObj.FAMId && this.saveRequestBodyObj.BAId) {
        this.getUsers();
      }
      /* if (!this.selectedTask.CustomerName || !this.selectedTask.CustomerAccountNo || this.selectedTask.CustomerAccountNo == '0') {
        this.getCustomerList();
      } */
    }
    this.applyCasecadingOnList().then(res => {
    });
  }

  // Filter based on BA
  SelectedBas(event, setValueFlag = false) {
    this.clearAssigneeDetails();
    if (setValueFlag) {
      this.removeExtraFromSaveObj();
      this.eventFromInformation.emit({
        operation: 'BA_DETAILS_UPDATE',
        data: this.saveRequestBodyObj,
      });
      if (this.saveRequestBodyObj.FAMId && this.saveRequestBodyObj.BAId) {
        this.getUsers();
      }
      /* if (!this.selectedTask.CustomerName || !this.selectedTask.CustomerAccountNo || this.selectedTask.CustomerAccountNo == '0') {
        this.getCustomerList();
      } */
    }
    this.applyCasecadingOnList().then(res => {
    });
  }

  // Filter based on DIV
  SelectedDivs(event, setValueFlag = false) {
    this.clearAssigneeDetails();
    if (setValueFlag) {
      this.removeExtraFromSaveObj();
      this.eventFromInformation.emit({
        operation: 'BA_DETAILS_UPDATE',
        data: this.saveRequestBodyObj,
      });
      /* if (!this.selectedTask.CustomerName || !this.selectedTask.CustomerAccountNo || this.selectedTask.CustomerAccountNo == '0') {
        this.getCustomerList();
      } */
    }
    this.applyCasecadingOnList().then(res => {
    });
  }

  // Filter based on Brands
  SelectedBrands(event, setValueFlag = false) {
    this.clearAssigneeDetails();
    if (setValueFlag) {
      this.removeExtraFromSaveObj();
      this.eventFromInformation.emit({
        operation: 'BA_DETAILS_UPDATE',
        data: this.saveRequestBodyObj,
      });
      /* if (!this.selectedTask.CustomerName || !this.selectedTask.CustomerAccountNo || this.selectedTask.CustomerAccountNo == '0') {
        this.getCustomerList();
      } */
    }
    this.applyCasecadingOnList().then(res => {
    });
  }

  resetFilter() {
    this.getViewData();
  }

  getViewData() {
    this.loadAllID = false;
    // this.loadAccess = true;
    if (this.dataOriginal.length > 0) {
      this.famData = this.dataOriginal.map(x => ({
        value: x.FAMID,
        label: x.FAMCode
      }));
      this.baData = this.dataOriginal.map(x => ({
        value: x.BAID,
        label: x.BACode
      }));
      this.divData = this.dataOriginal.map(x => ({
        value: x.DivisionID,
        label: x.DivisionCode
      }));
      this.brandData = this.brandOriginal.map(x => ({
        value: x.BrandID,
        label: x.BrandName
      }));

      // Need casecading filter effect
      this.famData = this.getDistinct(this.famData, 'value');
      this.baData = this.getDistinct(this.baData, 'value');
      this.divData = this.getDistinct(this.divData, 'value');
      this.brandData = this.getDistinct(this.brandData, 'value');
      this.SelectedFams({});
      this.loadAllID = true;
      this.loadingFlag();
    } else {
      this.ctlsTaskEditScreenService.getMasterData().subscribe(rawdata => {
        if (rawdata.Response != null) {

          this.dataOriginal = Object.assign(
            rawdata.Response.FamBADivBLFilterModel
          );
          this.brandOriginal = Object.assign(
            rawdata.Response.FamBABrandFilterModel
          );
        }

        this.applyCasecadingOnList().then(ele => {
          this.applyfilterOnLabel();
        });

        this.loadAllID = true;
        this.loadingFlag();
      });
    }
  }

  applyCasecadingOnList(field = '') {

    return new Promise(resolve => {

      setTimeout(() => {

        this.famData = this.filterList('fam').map(x => { return { value: x.FAMID, label: x.FAMCode } });;
        this.baData = this.filterList('ba').map(x => { return { value: x.BAID, label: x.BACode } });
        this.divData = this.filterList('div').map(x => { return { value: x.DivisionID, label: x.DivisionCode } });;
        this.brandData = this.filterList('brand').map(x => { return { value: x.BrandID, label: x.BrandName } });

        this.famData = this.getDistinct(this.famData, "value");
        this.baData = this.getDistinct(this.baData, "value");
        this.divData = this.getDistinct(this.divData, "value");
        this.brandData = this.getDistinct(this.brandData, "value");
        setTimeout(() => {
          this.applyfilterOnLabel()
        }, 5);
        resolve({ Status: "success" });
      }, 5);
    });
  }

  filterList(fieldName): Array<any> {

    var badivfamblList = JSON.parse(JSON.stringify(this.dataOriginal));
    var brandList = JSON.parse(JSON.stringify(this.brandOriginal));

    var listToReturn = [];

    if (this.filterMapping.FAMId != null && fieldName != 'fam') {
      badivfamblList = badivfamblList.filter(ele => {
        if (this.filterMapping.FAMId === ele.FAMID) {
          return ele;
        }
      });

      badivfamblList = this.filterBasedOnEligibility(true, true, true, badivfamblList, badivfamblList);
      brandList = this.filterBasedOnEligibility(false, true, true, badivfamblList, brandList);

    }

    if (this.filterMapping.BAId != null && fieldName != 'ba') {
      badivfamblList = badivfamblList.filter(ele => {
        if (this.filterMapping.BAId === ele.BAID) {
          return ele;
        }
      });

      badivfamblList = this.filterBasedOnEligibility(true, true, true, badivfamblList, badivfamblList);
      brandList = this.filterBasedOnEligibility(false, true, true, badivfamblList, brandList);
    }

    if (this.filterMapping.DIVId != null && this.filterMapping.DIVId.length > 0 && fieldName != 'div') {
      badivfamblList = badivfamblList.filter(ele => {
        if (this.filterMapping.DIVId.indexOf(ele.DivisionID) >= 0) {
          return ele;
        }
      });

      badivfamblList = this.filterBasedOnEligibility(true, true, true, badivfamblList, badivfamblList);
      brandList = this.filterBasedOnEligibility(false, true, true, badivfamblList, brandList);
    }

    if (this.filterMapping.BrandId != null && this.filterMapping.BrandId.length > 0 && fieldName != 'brand') {
      brandList = brandList.filter(ele => {
        if (this.filterMapping.BrandId.indexOf(ele.BrandID) >= 0) {
          return ele;
        }
      });

      badivfamblList = this.filterBasedOnEligibility(false, true, true, brandList, badivfamblList);
      brandList = this.filterBasedOnEligibility(false, true, true, brandList, brandList);
    }

    if (fieldName == 'fam' || fieldName == 'ba' || fieldName == 'div' || fieldName == 'bl') {
      listToReturn = badivfamblList;
    }
    else if (fieldName == 'brand') {
      listToReturn = brandList;
    }
    return listToReturn;
  }

  filterBasedOnEligibility(divEligible, famEligible, baEligible, fromList, toList): Array<any> {
    if (divEligible) {
      var divs = [];
      for (var i = 0; i < fromList.length; i++) {
        divs.push(fromList[i].DivisionID);
      };
      toList = this.filterByDIV(toList, divs);
    }
    if (famEligible) {
      var fams = [];
      for (var i = 0; i < fromList.length; i++) {
        fams.push(fromList[i].FAMID);
      };
      toList = this.filterByFAM(toList, fams);
    }
    if (baEligible) {
      var bas = [];
      for (var i = 0; i < fromList.length; i++) {
        bas.push(fromList[i].BAID);
      };
      toList = this.filterByBA(toList, bas);
    }

    return toList
  }

  getDistinct(list, key): any[] {
    let uniqueLists = [];
    let ids = [];
    list.forEach(element => {
      if (!this.includes(ids, element[key]) && element[key] != null) {
        uniqueLists.push(element);
        ids.push(element[key]);
      }
    });

    uniqueLists = uniqueLists.sort(function (a, b) {
      return a.label.localeCompare(b.label);
    });

    return uniqueLists;
  }

  includes(container, value) {
    let returnValue = false;
    const pos = container.indexOf(value);
    if (pos >= 0) {
      returnValue = true;
    }
    return returnValue;
  }

  filterByFAM(list, famList): any {
    return list.filter(ele => {
      if (famList.indexOf(ele.FAMID) >= 0) {
        return ele;
      }
    });
  }

  filterByBA(list, baList): any {
    return list.filter(ele => {
      if (baList.indexOf(ele.BAID) >= 0) {
        return ele;
      }
    });
  }

  filterByDIV(list, divList): any {
    return list.filter(ele => {
      if (divList.indexOf(ele.DivisionID) >= 0) {
        return ele;
      }
    });
  }

  reomveExtraIds(displayOnly = false) {

    var famids = [];
    var baids = [];
    var divids = [];
    var brandids = [];

    if (this.filterMapping.FAMId) {
      let dat = this.famData.find(e => e.value === this.filterMapping.FAMId);
      if (dat.value)
        famids.push(dat.value);
    }

    if (this.filterMapping.BAId) {
      let dat = this.baData.find(e => e.value === this.filterMapping.BAId);
      if (dat.value)
        baids.push(dat.value);
    }

    if (this.filterMapping.DIVId != null && this.filterMapping.DIVId.length > 0) {
      this.filterMapping.DIVId.forEach(element => {
        if (this.divData.map(ele => { return ele.value }).indexOf(element) >= 0) {
          divids.push(element);
        }
      });
    }

    if (this.filterMapping.BrandId != null && this.filterMapping.BrandId.length > 0) {
      this.filterMapping.BrandId.forEach(element => {
        if (this.brandData.map(ele => { return ele.value }).indexOf(element) >= 0) {
          brandids.push(element);
        }
      });
    }

    if (displayOnly) {
      this.selectedCount.famCount = this.famData.filter(ele => { if (famids.indexOf(ele.value) >= 0) { return ele } });
      this.selectedCount.baCount = this.baData.filter(ele => { if (baids.indexOf(ele.value) >= 0) { return ele } });
      this.selectedCount.divCount = this.divData.filter(ele => { if (divids.indexOf(ele.value) >= 0) { return ele } });
      this.selectedCount.brandCount = this.brandData.filter(ele => { if (brandids.indexOf(ele.value) >= 0) { return ele } });
    } else {
      this.filterMapping.FAMId = famids && famids.length ? famids[0]: null;
      this.filterMapping.BAId = baids && baids.length ? baids[0] : null;
      this.filterMapping.DIVId = divids;
      this.filterMapping.BrandId = brandids;
    }

  }

  loadingFlag() {
    /* if (this.loadSelectedID == true && this.loadAllID == true) {
      this.loadAccess = false;
    } else {
      this.loadAccess = true;
    } */
  }

  applyfilterOnLabel() {
    this.reomveExtraIds(true);
    if (this.filterMapping.FAMId != null && this.filterMapping.FAMId.length >= 4) {
      if (this.famDDL) {
        this.famDDL.valuesAsString = this.selectedCount.famCount.length + ' items selected';
      }
    }
    else if (this.filterMapping.FAMId != null && this.filterMapping.FAMId.length < 4 && this.filterMapping.FAMId.length >= 1) {
      if (this.famDDL) {
        this.famDDL.valuesAsString = this.selectedCount.famCount.map(ele => ele.label).join(', ');
      }
    }

    if (this.filterMapping.BAId != null && this.filterMapping.BAId.length >= 4) {
      if (this.baDDL) {
        this.baDDL.valuesAsString = this.selectedCount.baCount.length + ' items selected';
      }
    }
    else if (this.filterMapping.BAId != null && this.filterMapping.BAId.length < 4 && this.filterMapping.BAId.length >= 1) {
      if (this.baDDL) {
        this.baDDL.valuesAsString = this.selectedCount.baCount.map(ele => ele.label).join(', ');
      }
    }

    if (this.filterMapping.DIVId != null && this.filterMapping.DIVId.length >= 4) {
      if (this.divDDL) {
        this.divDDL.valuesAsString = this.selectedCount.divCount.length + ' items selected';
      }
    }
    else if (this.filterMapping.DIVId != null && this.filterMapping.DIVId.length < 4 && this.filterMapping.DIVId.length >= 1) {
      if (this.divDDL) {
        this.divDDL.valuesAsString = this.selectedCount.divCount.map(ele => ele.label).join(', ');
      }
    }

    if (this.filterMapping.BrandId != null && this.filterMapping.BrandId.length >= 4) {
      if (this.brandDDL) {
        this.brandDDL.valuesAsString = this.selectedCount.brandCount.length + ' items selected';
      }
    }
    else if (this.filterMapping.BrandId != null && this.filterMapping.BrandId.length < 4 && this.filterMapping.BrandId.length >= 1) {
      if (this.brandDDL) {
        this.brandDDL.valuesAsString = this.selectedCount.brandCount.map(ele => ele.label).join(', ');
      }
    }

  }
  clearAssigneeDetails() {
    this.selectedTask.Assignee = null;
    this.selectedTask.AssigneeID = null;
    this.saveRequestBodyObj.AssigneeID = null;
    this.saveRequestBodyObj.Assignee = null;
    this.assigneeData = {};
    this.eventFromInformation.emit({
      operation: 'ASSIGNEE_UPDATE',
      data: this.saveRequestBodyObj,
    });
  }
}
