import { Component, OnChanges, OnDestroy, OnInit, SimpleChanges, Output, EventEmitter, Input } from "@angular/core";
import { Subscription } from "rxjs";
import { DashboardWidgetService } from "../../../service/dashboard-widget.service";
import { LostbusinessCustomerService } from "../../../service/lostbusiness-customer.service";
import { ParsedChartData, ParsedTrendData, ParsedTrendDataResponse } from "../../models/classes/chart-data-models.class";
import { ChartDataUtility } from "../../utils/chart-data-utility.module";
import { CHART_STATE_OPTIONS } from "src/app/shared/enums/chart-state-options.enum";
import { environment } from 'src/environments/environment';
@Component({
    selector: 'lb-chart-holder',
    templateUrl: './chart-holder.component.html',
    styleUrls: ['chart-holder.component.scss']
})
export class ChartHolderComponent implements OnInit, OnDestroy, OnChanges {
    private imagepath = environment.imagepath;
    @Input() filterBy: string = 'Customers';
    @Output() clickParams: EventEmitter<{ count: number, period: string, type: string } | null> = new EventEmitter<{ count: number, period: string, type: string } | null>();
    filterByName = 'Customers';
    public chartViewType: string;
    private _viewByYear: boolean;
    public get viewByYear(): boolean {
        return this._viewByYear;
    }
    public set viewByYear(value: boolean) {
        this.chartViewType = (value) ? 'Year' : 'Month';
        this._viewByYear = value;
    }

    renderData: ParsedChartData = new ParsedChartData();
    rawData: ParsedTrendDataResponse = new ParsedTrendDataResponse();
    parsedData: ParsedChartData = new ParsedChartData();

    readonly STATE_OPTIONS = CHART_STATE_OPTIONS;
    state: CHART_STATE_OPTIONS = CHART_STATE_OPTIONS.UNKNOWN;
    apiSub: Subscription = new Subscription();

    constructor(private dashService: DashboardWidgetService, private lostBusinessService: LostbusinessCustomerService) {
        this.viewByYear = false;
    }
    ngOnInit(): void {
        this.state = CHART_STATE_OPTIONS.LOADING;
        this.apiSub = this.dashService.getLostCustomeerTrendData(this.viewByYear).subscribe(res => {
            if(res.Status.toLowerCase() === 'success') {
                if (!(res.Response.Month) && (!(res.Response.Year && res.Response.Year.length))) {
                    this.state = CHART_STATE_OPTIONS.NO_DATA;
                } else {
                    this.state = CHART_STATE_OPTIONS.SUCCESS;
                }
                //this.state = CHART_STATE_OPTIONS.SUCCESS;
                if(this.viewByYear){
                    this.rawData.Year = ChartDataUtility.addTimestamp(res.Response.Year);
                }
                else{
                    this.rawData.Month = ChartDataUtility.addTimestamp(res.Response.Month);                    
                }
                //this.viewByMonthEvent();
                this.parseData(this.rawData[this.viewByYear ? 'Year' : 'Month']);
            } else {
                this.state = CHART_STATE_OPTIONS.ERROR;
                this.rawData = new ParsedTrendDataResponse();
            }
        }, error => {
            this.state = CHART_STATE_OPTIONS.ERROR;
            this.rawData = new ParsedTrendDataResponse();
        })
    }
    ngOnChanges(changes: SimpleChanges): void {
        if(this.lostBusinessService.filterObj1.ViewedBy == 'Contact')
        {
            this.filterByName = 'Contacts';
        }
        else{
            this.filterByName = 'Customers';
        }
    }
    ngOnDestroy(): void {
        if (this.apiSub && !this.apiSub.closed) this.apiSub.unsubscribe();
    }

    viewByMonthEvent(): void {
        this.state = CHART_STATE_OPTIONS.LOADING;
        this.dashService.getLostCustomeerTrendData(this.viewByYear).subscribe(res => {
            if(res.Status.toLowerCase() === 'success') {
                if (!(res.Response.Month) && (!(res.Response.Year && res.Response.Year.length))) {
                    this.state = CHART_STATE_OPTIONS.NO_DATA;
                } else {
                    this.state = CHART_STATE_OPTIONS.SUCCESS;
                }
                if(this.viewByYear){
                    this.rawData.Year = ChartDataUtility.addTimestamp(res.Response.Year);
                }
                else{
                    this.rawData.Month = ChartDataUtility.addTimestamp(res.Response.Month);                    
                }
                this.parseData(this.rawData[this.viewByYear ? 'Year' : 'Month']);
            } else {
                this.state = CHART_STATE_OPTIONS.ERROR;
                this.rawData = new ParsedTrendDataResponse();
            }
        }, error => {
            this.state = CHART_STATE_OPTIONS.ERROR;
            this.rawData = new ParsedTrendDataResponse();
        })        
    }
    parseData(raw: ParsedTrendData[]) {
        let data: ParsedChartData = new ParsedChartData();
        data.xAxis = ChartDataUtility.generateAxisLabels(ChartDataUtility.sort(raw, false));
        data.yAxis = ChartDataUtility.dataExtraction(ChartDataUtility.sort(raw, false));
        this.renderData = { ...data };
    }

    childEvent(event) {
        this.clickParams.emit(event);
    }
}