import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl,Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/api';

@Component({
  selector: 'app-ctls-common-comment',
  templateUrl: './ctls-common-comment.component.html',
  styleUrls: ['./ctls-common-comment.component.scss'],
})
export class CtlsCommonCommentComponent implements OnInit {
  @ViewChild('commentFocus') commentFocus: ElementRef;

  constructor(
    public config: DynamicDialogConfig,
    public dynamicDialogRef: DynamicDialogRef,
    private fb: FormBuilder
  ) {}
  /** string for comment text area */
  comment: string;

  /** form declaration */
  commentForm = this.fb.group({
    comment: ['', Validators.compose([Validators.required,this.noWhitespaceValidator])],
  });
  ngOnInit() {
    setTimeout(() => {
      this.commentFocus.nativeElement.focus();
    }, 500);
  }

  /**Close the popup */
  close(event) {
    this.dynamicDialogRef.close('close');
    this.commentForm.reset();
  }

  noWhitespaceValidator(control: FormControl) {
      const isWhitespace = (control.value || '').trim().length === 0;
      const isValid = !isWhitespace;
      return isValid ? null : { 'whitespace': true };
  }
  /**Save comment */
  save() {
    this.config.data.Comment = this.commentForm.get('comment').value
      ? this.commentForm.get('comment').value
      : '';
    this.dynamicDialogRef.close(this.config.data);
    this.commentForm.reset();
  }
}
