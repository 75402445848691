import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { CtlsVocListingService } from '../../ctls-services/ctls-voc-listing.service';
import { environment } from 'src/environments/environment';
import * as XLSX from 'xlsx';
import { CtlsVocEditScreenService } from '../../ctls-services/ctls-voc-edit-screen.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-ctls-voc-attachment-preview',
  templateUrl: './ctls-voc-attachment-preview.component.html',
  styleUrls: ['./ctls-voc-attachment-preview.component.scss']
})
export class CtlsVocAttachmentPreviewComponent implements OnInit {
  @Input() attachment: any;
  @Input() showpopup: any;
  attachmentHeader: any;
  imagedownload: string;
  selectedAttachment: any;
  resizedDialog: boolean;
  displayAttachmentDialog: boolean;
  imageSrc: string;
  currentPopupMode: string;
  docURL: any;
  docViewerURL: string;
  docViewer: string;
  private imagepath = environment.imagepath;
  excelReadData: any;
  excelColumns: string[];
  destroy$ = new Subject();
  widthData: number = 100;
  heightData: number = 100;
  @Output()
eventFromAttachmentScreen: EventEmitter<any> = new EventEmitter<any>();
  constructor(private ctlsVocListingService: CtlsVocListingService,
    public ctlsTaskEditScreenService: CtlsVocEditScreenService,
  ) { }

  ngOnInit() {
  }

  hideAttachmentDialog() {
    this.displayAttachmentDialog = false;
    this.currentPopupMode = ""
    this.docURL = '';
    this.docViewerURL = '';
    this.docViewer = '';
    this.excelColumns = [];
    this.excelReadData = [];
    
    this.eventFromAttachmentScreen.emit({
      operation: 'CLOSE_ATTACHMENT',
      attachment: [],
    })
  }
  ngOnChanges(changes: SimpleChanges) {
      this.widthData = 100;
      this.heightData = 100;
      this.attachmentHeader = this.attachment.FileName || '';
      const extension = this.attachment.FileName.split('.').pop().toLowerCase();
      this.imagedownload = this.imagepath + 'voc-listing/white-download.png';
      this.selectedAttachment = this.attachment;
      console.log("attachments: ", this.attachment)
      this.resizedDialog = false;
      this.displayAttachmentDialog = true;
      // this.renderer.addClass(document.body, 'show-expand-map-view');
      let imageExtensions = ['jpg','jpeg','png','tiff','bmp'];
      this.imageSrc = '';
      
      if(imageExtensions.includes(this.attachment.extension)){ 
        this.currentPopupMode = "IMG"
        this.imageSrc = this.attachment.URL;
      }
      
      if(extension==="xlsx" || extension==="xls"){
        this.currentPopupMode = "EXCEL"
        this.ctlsVocListingService.GetFileData(this.attachment.VOCTaskFilesListID).subscribe((dataUrl) => {
          const { base64 } = this.extractBase64Data(dataUrl.Response);
          this.displayExcel(base64)
        }, (error) => console.log(error));
      }else if(extension==="docx" || extension==="doc" || extension ==='pdf' || extension === 'ppt' || extension === 'pptx'){
        this.currentPopupMode = "DOC"
        this.docURL = this.attachment.URL;
        this.docViewerURL = 'https://docs.google.com/gview?url=%URL%&embedded=true'
        this.docViewer = 'google';
      }
  }
  downloadImg(img) {
    this.ctlsTaskEditScreenService
      .GetFileUrl(img.VOCTaskFilesListID)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res.Response) {
          const downloadLink = document.createElement('a');
          downloadLink.href = res.Response;
          downloadLink.download = img.FileName;
          downloadLink.click();
        }
      });
  }

  zoomIn() {
    this.widthData +=50;
    this.heightData +=50;
  }
  zoomOut() {
    if(this.widthData > 100) {
      this.widthData -=50;
    }
    if(this.heightData > 100) {
      this.heightData -=50;
    }
  }
  fitToWidth() {
    if(this.widthData == 100 ) {
      this.widthData = 150;
    } else
      this.widthData = 100
      if(this.heightData == 100 ) {    
        this.heightData = 150;
      } else
      this.heightData = 100;
  }
  displayExcel(base64Data: string) {
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    const reader = new FileReader();
    reader.onload = (e: any) => {
        const workbook = XLSX.read(e.target.result, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const data = XLSX.utils.sheet_to_json(sheet);
        this.excelReadData = data;

        if (data.length > 0) {
            this.excelColumns = Object.keys(data[0]);
        }
    };
    reader.readAsBinaryString(blob);
  }

  extractBase64Data(dataUrl: string): { mimeType: string, base64: string } {
    const base64ContentArray = dataUrl.split(',');
    const mimeType = base64ContentArray[0].match(/:(.*?);/)[1];
    const base64Data = base64ContentArray[1];
    return { mimeType, base64: base64Data };
  }
}
