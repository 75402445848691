import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DynamicDialogConfig } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CtlsTaskEditScreenService } from '../Modules/ctls/ctls-shared/ctls-services/ctls-task-edit-screen.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-audio-preview',
  templateUrl: './audio-preview.component.html',
  styleUrls: ['./audio-preview.component.scss'],
})
export class AudioPreviewComponent implements OnInit {
  private imagepath = environment.imagepath;
  url: any;
  constructor(
    public config: DynamicDialogConfig,
    public sanitizer: DomSanitizer,
    public ctlsTaskEditScreenService: CtlsTaskEditScreenService
  ) {}
  destroy$ = new Subject();

  attachmentArray: any;
  fileURL: string;
  ngOnInit() {
    this.fileURL = this.imagepath + 'audio-document.svg';
    this.attachmentArray = this.config.data;
  }

  download(file) {
    const downloadLink = document.createElement('a');
    downloadLink.href = file.fileURL;
    downloadLink.download = file.fileName;
    downloadLink.click();
  }
}
